import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "@/scss/styles.scss";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { PiniaSharedState } from "pinia-shared-state";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(PiniaSharedState({ enable: true, initialize: true, type: "native" }));
const mapKey = process.env.VUE_APP_MAP_KEY ? process.env.VUE_APP_MAP_KEY : ``;
createApp(App)
  .use(pinia)
  .use(router)
  .use(VueGoogleMaps, {
    load: {
      key: mapKey,
      libraries: "places",
      language: "en",
      region: "us",
    },
  })
  .use(Toast, { position: POSITION.TOP_RIGHT })
  .mount("#app");
