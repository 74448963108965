import { RouteRecordRaw } from "vue-router";
import MainLayout from "@/layouts/MainLayout.vue";
import EmptyLayout from "@/layouts/EmptyLayout.vue";
import { RoutesEnum } from "@/enums/routesEnum";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Main",
    redirect: { name: RoutesEnum.DASHBOARD },
    meta: { requiresAuth: true },
  },
  {
    path: "/users",
    name: RoutesEnum.USERS,
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/UsersPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/dashboard",
    name: RoutesEnum.DASHBOARD,
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/BlockPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/content",
    name: RoutesEnum.CONTENT,
    component: () =>
      import(/* webpackChunkName: "content" */ "../views/ContentPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/content/create",
    name: RoutesEnum.CONTENT_CREATE,
    component: () =>
      import(/* webpackChunkName: "content" */ "../views/ContentPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/createHotspot",
    name: RoutesEnum.CREATEHOTSPOT,
    component: () =>
      import(/* webpackChunkName: "content" */ "../views/ContentPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: RoutesEnum.LOGIN,
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginPage.vue"),
    meta: { layout: EmptyLayout, requiresAuth: false },
  },
  {
    path: "/rewards/:monthType",
    name: RoutesEnum.REWARDS,
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/RewardsPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/:NotFound(.*)*",
    name: RoutesEnum.NOTFOUND,
    component: () =>
      import(/* webpackChunkName: "notfound" */ "../views/NotFoundPage.vue"),
    meta: { layout: EmptyLayout },
  },
];
export default routes;
