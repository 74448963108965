import { defineStore } from "pinia";
import { ref } from "vue";
export const useFormStore = defineStore("form", {
  state: () => {
    return { isOpenCreateForm: false };
  },
  actions: {
    openForm() {
      this.isOpenCreateForm = true;
    },
    closeForm() {
      this.isOpenCreateForm = false;
    },
  },
});
