import axiosClient from "./index";
import { AxiosResponse } from "axios";

export default {
  login(payload: {
    email: string;
    password: string;
    deviceId: string;
  }): Promise<AxiosResponse> {
    return axiosClient.post("v1/auth/sign-in", payload);
  },
  signOut(): Promise<AxiosResponse> {
    return axiosClient.delete("v1/auth/sign-out");
  },
};
