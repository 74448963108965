export enum RoutesEnum {
  DASHBOARD = "Dashboard",
  USERS = "Users",
  CONTENT = "Content",
  CONTENT_CREATE = "Content Create",
  LOGIN = "Login",
  CREATEHOTSPOT = "createHotspot",
  REWARDS = "Rewards",
  NOTFOUND = "NotFound",
}
